import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import {
	Modal,
	Icon,
	Form,
	Grid,
	Button,
	Input,
	Message
} from 'semantic-ui-react';
import RequestManager from './RequestManager';

class ChangePassword extends React.Component {

	state = {
		userStatus: this.props.userStatus,
		isLoading: false,
		hasError: false,
		errorTitle: '',
		errorMessage: '',
		errorField: '',
		form: {
			oldPassword: '',
			newPassword: '',
			confirmPassword: ''
		}
	};

	previousVisible = null;

	constructor(state){
		super(state);
		this.saveButtonHandler = this.saveButtonHandler.bind(this);
		this.handleValueChange = this.handleValueChange.bind(this);
		this.receivedResponse = this.receivedResponse.bind(this);

	}

	shouldComponentUpdate(nextProps, nextState){
		this.previousVisible = this.props.open;
		return true;
	}

	componentDidUpdate(){
		if(this.previousVisible !== this.props.open && this.props.open){
			this.setState(function(state){
				state.userStatus = this.props.userStatus;
				state.form.oldPassword = '';
				state.form.newPassword = '';
				state.form.confirmPassword = '';
				return state;
			});
		}
	}

	handleValueChange(value, name){
		const data = value;
		this.setState(function(state){
			if(data > ' '){
				state.form[name] = data;
			}else{
				state.form[name] = null;
			}
			return state;
		});
	}

	closePopUP(){
		this.setState(function(state){
			state.isLoading = false;
			state.hasError = false;
			return state;
		});
	}

	saveButtonHandler(){
		this.setLoadingState(true);
		if(this.validateFields()){
			var dataToSend = {
				oldPassword: this.state.form.oldPassword,
				newPassword: this.state.form.newPassword
			};
			RequestManager.requestAPI(
				'/change-password',
				dataToSend,
				this.receivedResponse
			);
		}
	}

	receivedResponse(error, data){
		var hasError = false;
		var errorField = '';
		var errorTitle = '';
		var errorMessage = '';
		if(error){
			if (error === 403 || error === 404){
				hasError = true;
				errorField = 'oldPassword';
				errorTitle = 'Senha inválida!';
				errorMessage = 'A senha informada não confere, tente novamente.';
			}else if (error === 402){
				hasError = true;
				errorField = 'newPassword';
				errorTitle = 'Nova Senha inválida!';
				errorMessage = 'A nova senha informada não é valida, tente novamente.';
			}
		}
		this.setState(function(state){
			state.isLoading = false;
			state.hasError = hasError;
			state.errorField = errorField;
			state.errorTitle = errorTitle;
			state.errorMessage = errorMessage;
			return state;
		});
		if (!hasError){
			if(typeof this.props.onDone === 'function'){
				this.props.onDone();
			}else if(typeof this.props.onCancelClick === 'function'){
				this.props.onCancelClick();
			}
			this.closePopUP();
		}
	}

	setLoadingState(isLoading){
		this.setState(function(state){
			state.isLoading = isLoading;
			return state;
		});
	}

	validateFields(){
		var hasError = false;
		var errorField = null;
		var errorTitle = null;
		var errorMessage = null;
		if (this.state.userStatus !== "E" && this.state.form.oldPassword <= ''){
			hasError = true;
			errorField = 'oldPassword';
			errorTitle = 'Informe a senha atual';
			errorMessage = 'Preencha os campos obrigatórios e tente novamente.';
		}else if (this.state.form.newPassword <= ''){
			hasError = true;
			errorField = 'newPassword';
			errorTitle = 'Informe a nova senha';
			errorMessage = 'Preencha os campos obrigatórios e tente novamente.';
		}else if (this.state.form.newPassword !== this.state.form.confirmPassword){
			hasError = true;
			errorField = 'confirmPassword';
			errorTitle = 'Confirmação de senha inválida';
			errorMessage = 'A confirmação da senha precisa ser igual a nova senha.';
		}
		if (hasError){
			this.setState(function(state){
				state.isLoading = false;
				state.hasError = true;
				state.errorField = errorField;
				state.errorTitle = errorTitle;
				state.errorMessage = errorMessage;
				return state;
			});
			return false;
		}
		return true;
	}

	handleFormChange(event, fieldName, substring){
		var data = event.target.value;
		if(typeof substring != 'undefined'){
			data = data.substring(0, substring);
		}
		this.setState(function(state){
			state.form[fieldName] = data;
			return state;
		});
	}

	render(){
		return <Modal open={this.props.open} className="animated fadeInDown" size="small" >
			<Modal.Header>
				<Icon name="lock" />
				{this.state.userStatus==="E"?"Crie uma Nova Senha":"Trocar a Senha"}
			</Modal.Header>
			<Modal.Content>
				{
					(this.state.hasError) ? 
					<Message negative className="animated fadeInDown">
						<Message.Header>{this.state.errorTitle}</Message.Header>
						<p>{this.state.errorMessage}</p>
					</Message>
					: ''
				}
				<Form>
					<Grid relaxed columns="2">
						{	this.state.userStatus !== "E"
							?	<Grid.Column>
									<Form.Field required error={this.state.errorField==='oldPassword'} >
										<label>Senha Atual</label>
										<Input maxLength="100" disabled={this.state.isLoading} icon='lock'  placeholder='' type='password' value={this.state.form.oldPassword} onChange={(event)=>{this.handleFormChange(event,'oldPassword')}} />
									</Form.Field>
								</Grid.Column>
							:	<></>
						}						
						<Grid.Column>
							<Form.Field required error={this.state.errorField==='newPassword'}>
								<label>Nova Senha</label>
								<Input maxLength="100" disabled={this.state.isLoading} icon='lock'  placeholder='' type='password' value={this.state.form.newPassword} onChange={(event)=>{this.handleFormChange(event,'newPassword')}} />
							</Form.Field>
						</Grid.Column>
					</Grid>
					<Grid relaxed columns="2">
						{	this.state.userStatus==="E"
							?	<></>
							:	<Grid.Column></Grid.Column>
						}
						<Grid.Column>
							<Form.Field required error={this.state.errorField==='confirmPassword'} >
								<label>Confirme a Senha</label>
								<Input maxLength="100" disabled={this.state.isLoading} icon='lock'  placeholder='' type='password' value={this.state.form.confirmPassword} onChange={(event)=>{this.handleFormChange(event,'confirmPassword')}} />
							</Form.Field>
						</Grid.Column>
					</Grid>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				{	this.state.userStatus !== "E"
					?	<Button disabled={this.state.isLoading} onClick={()=>{this.props.onCancelClick(); this.closePopUP()}}>
							Cancela
						</Button>
					:	<></>
				}
				<Button loading={this.state.isLoading} primary onClick={this.saveButtonHandler}>Confirma</Button>
			</Modal.Actions>
		</Modal>;
	}
}

export default ChangePassword;