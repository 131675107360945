import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import './Messenger.css';
import {
	Menu, 
	Header,
	Image,
	Button,
	Dropdown,
	Comment,
	TextArea,
	Icon,
	Grid,
	Popup,
	Confirm
} from 'semantic-ui-react';
import {
	Link
} from 'react-router-dom';

import eIcon from './media/eLogo.png';
import profileIcon from './media/profile.png';

import ReactMarkdown from 'react-markdown';
import CodeBlock from "./CodeBlock";
import RequestManager from './RequestManager';
import DatetimeManager from './DatetimeManager';
import ChangePassword from './ChangePassword';

class Messenger extends React.Component {

	state = {
		isLoading: true,
		isInitialized: false,
		userData: {},
		menuActive: false,
		students: [],
		consults: [],
		activeConsultKey: null,
		activeConsultData: null,
		activeStudentData: null,
		messageInputActive: false,
		messengerInput: '',
		isSendingMessage: false,
		isMessageDeletePending: false
	};

	elemContentScroll = null;
	messengerRefreshTimeout = null;

	constructor(state){
		super(state);
		this.receivedLoadBaseData = this.receivedLoadBaseData.bind(this);
		this.loadStudents = this.loadStudents.bind(this);
		this.closeMessenger = this.closeMessenger.bind(this);
		this.receivedStudentData = this.receivedStudentData.bind(this);
		this.loadMessages = this.loadMessages.bind(this);
		this.receivedMessages = this.receivedMessages.bind(this);
		this.loadConsults = this.loadConsults.bind(this);
		this.receivedConsults = this.receivedConsults.bind(this);		
		this.messengerConsultClickHandler = this.messengerConsultClickHandler.bind(this);
		this.menuMobileClickHandler = this.menuMobileClickHandler.bind(this);
		this.messengerInputChangeHandler = this.messengerInputChangeHandler.bind(this);
		this.messengerInputFocusHandler = this.messengerInputFocusHandler.bind(this);
		this.messengerScrollCanvas = this.messengerScrollCanvas.bind(this);				
		this.sendMessage = this.sendMessage.bind(this);
		this.closeChat = this.closeChat.bind(this);
		this.onSentMessage = this.onSentMessage.bind(this);
		this.showDeleteMessageConfirmation = this.showDeleteMessageConfirmation.bind(this);
		this.deleteLastMessage = this.deleteLastMessage.bind(this);
		this.onDeletedMessage = this.onDeletedMessage.bind(this);
		this.setCandidateToMentor = this.setCandidateToMentor.bind(this);
		this.elemContentScroll = React.createRef();
	}

	componentDidMount(){
		RequestManager.requestAPI(
			'/mentor/loadbasedata', {},
			this.receivedLoadBaseData
		);
	}

	receivedLoadBaseData(hasError, receivedData){
		if(hasError){
			RequestManager.goBackToLogin();
			return;
		}
		this.setState(function(state){
			state.userData = receivedData.userData;
			state.isInitialized = true;
			return state;
		});
		this.loadStudents();
	}

	loadStudents(){
		RequestManager.requestAPI(
			'/mentor/messenger/loadstudents',
			null,
			this.receivedStudentData
		);
	}

	receivedStudentData(hasError, receivedData){
		if(hasError){
			RequestManager.goBackToLogin();
		}else if (receivedData.students !== null){
			this.setState(function(state){
				state.students = receivedData.students;
				state.isInitialized = true;
				return state;
			});
			this.messengerRefreshTimeout = setTimeout(
				this.loadStudents,
				300000
			);	
		}
	}

	loadConsults(userId){
		var sendData = {
			userId: userId
		};
		RequestManager.requestAPI(
			'/mentor/messenger/loadconsults',
			sendData,
			this.receivedConsults
		);
	}

	receivedConsults(hasError, receivedData){
		if(hasError){
			//RequestManager.goBackToLogin();
			return;
		}
		if (receivedData.userId !== null && receivedData.consults != null){
			var consults = [];
			for (var i=0; i<receivedData.consults.length; i++){
				var consult = receivedData.consults[i];
				var key = receivedData.userId+'-'+consult.journeyId+'-'+consult.trailId;
				consults.push({
					key: key,
					text: consult.journeyTitle+' / '+consult.trailTitle,
					value: key
				});
			}
			this.setState(function(state){
				state.consults = consults;
				state.activeStudentData = receivedData;
				return state;
			});
		}
	}

	loadMessages(userId, journeyId, trailId){
		var sendData = {
			userId: userId, 
			journeyId: journeyId,
			trailId: trailId
		};
		RequestManager.requestAPI(
			'/mentor/messenger/loadmessages',
			sendData,
			this.receivedMessages
		);
	}

	receivedMessages(hasError, receivedData){
		if(hasError){
			//RequestManager.goBackToLogin();
			return;
		}
		if (receivedData.userId === null || receivedData.journeyId === null || receivedData.trailId === null){
			return;
		}
		var consultKey = receivedData.userId+'-'+receivedData.journeyId+'-'+receivedData.trailId;
		if (this.state.activeConsultKey === consultKey){
			this.setState(function(state){
				state.isSendingMessage = false;
				state.activeConsultData = receivedData;
				return state;
			});
			setTimeout(
				this.messengerScrollCanvas,
				100
			);	
		}
	}

	sendMessage(){
		if (this.state.messengerInput === null
			|| this.state.messengerInput.length < 10
				|| this.state.activeConsultData === null
					|| this.state.activeConsultData.userId === null
						|| this.state.activeConsultData.journeyId === null
							|| this.state.activeConsultData.trailId === null)
		{
			return;
		}
		var sendData = {
			userId: this.state.activeConsultData.userId,
			journeyId: this.state.activeConsultData.journeyId,
			trailId: this.state.activeConsultData.trailId,
			message: this.state.messengerInput,
			finishChat: false
		};
		RequestManager.requestAPI(
			'/mentor/messenger/sendmessage',
			sendData,
			this.onSentMessage
		);
		this.setState(function(state){
			state.isSendingMessage = true;
			state.messageInputActive = false;
			state.messengerInput = '';
			return state;
		});
	}

	setCandidateToMentor(userId){
		RequestManager.requestAPI(
			'/mentor/login-as-student',
			{userId: userId},
			this.responseSetCandidateToMentor
		);
	}

	responseSetCandidateToMentor(hasError, receivedData){
		if(!hasError){
			window.open(
				process.env.REACT_APP_STUDENT_DOMAIN, 
				'_blank'
			);
		}
	}

	closeChat(){
		var sendData = {
			userId: this.state.activeConsultData.userId,
			journeyId: this.state.activeConsultData.journeyId,
			trailId: this.state.activeConsultData.trailId,
			message: this.state.messengerInput,
			finishChat: true
		};
		RequestManager.requestAPI(
			'/mentor/messenger/sendmessage',
			sendData,
			this.onSentMessage
		);
		this.setState(function(state){
			state.isSendingMessage = true;
			state.messageInputActive = false;
			state.messengerInput = '';
			return state;
		});
		window.location.reload();
	}

	onSentMessage(hasError, receivedData){
		if(!hasError){
			if (this.state.activeConsultData === null
				|| receivedData.userId === null
				|| receivedData.journeyId === null
				|| receivedData.trailId === null
				|| receivedData.userId !== this.state.activeConsultData.userId
				|| receivedData.journeyId !== this.state.activeConsultData.journeyId
				|| receivedData.trailId !== this.state.activeConsultData.trailId)
			{
				return;
			}
			this.loadMessages(receivedData.userId, receivedData.journeyId, receivedData.trailId);
		}
	}

	showDeleteMessageConfirmation(visibility){
		this.setState(function(state){
			state.isMessageDeletePending = visibility;
			return state;
		});
	}

	deleteLastMessage(){
		if (this.state.activeConsultData === null
				|| this.state.activeConsultData.userId === null
					|| this.state.activeConsultData.journeyId === null
						|| this.state.activeConsultData.trailId === null
							|| this.state.activeConsultData.messages.length <= 0)
		{
			return;
		}
		var lastMessageIndex = this.state.activeConsultData.messages.length - 1;
		if (this.state.activeConsultData.messages[lastMessageIndex].type === 'A'){
			var sendData = {
				userId: this.state.activeConsultData.userId,
				journeyId: this.state.activeConsultData.journeyId,
				trailId: this.state.activeConsultData.trailId,
				messageId: this.state.activeConsultData.messages[lastMessageIndex].id
			};
			RequestManager.requestAPI(
				'/mentor/messenger/deletemessage',
				sendData,
				this.onDeletedMessage
			);
			this.setState(function(state){
				state.isMessageDeletePending = false;
				return state;
			});
		}
	}

	onDeletedMessage(hasError, receivedData){
		if(!hasError){
			if (this.state.activeConsultData === null
				|| receivedData.userId === null
				|| receivedData.journeyId === null
				|| receivedData.trailId === null
				|| receivedData.userId !== this.state.activeConsultData.userId
				|| receivedData.journeyId !== this.state.activeConsultData.journeyId
				|| receivedData.trailId !== this.state.activeConsultData.trailId)
			{
				return;
			}
			this.loadMessages(receivedData.userId, receivedData.journeyId, receivedData.trailId);
		}
	}

	messengerConsultClickHandler(studentIndex){
		if (this.state.students === null || this.state.students.length <= studentIndex){
			return;
		}
		var student = this.state.students[studentIndex];
		var consultKey = student.id+'-'+student.journeyId+'-'+student.trailId;
		this.setState(function(state){
			state.activeConsultKey = consultKey;
			state.activeConsultData = null;
			state.messageInputActive = false;
			state.messengerInput = '';
			state.consults = [];
			return state;
		});
		this.loadMessages(student.id, student.journeyId, student.trailId);
		this.loadConsults(student.id);
	}

	closeMessenger(){
		if (this.messengerRefreshTimeout){
			clearTimeout(this.messengerRefreshTimeout);
		}
		this.messengerRefreshTimeout = null;
		this.loadStudents();
		this.setState(function(state){
			state.activeStudentData = null;
			state.activeConsultKey = null;
			state.activeConsultData = null;
			state.messageInputActive = false;
			state.messengerInput = '';
			state.consults = [];
			return state;
		});
	}

	logout(){
		RequestManager.logout();
	}

	setLoadingState(isLoading){
		this.setState(function(state){
			state.isLoading = isLoading;
			return state;
		});
	}

	indexToChar(index){
		return String.fromCharCode(65 + (index));
	}

	menuMobileClickHandler(){
		var previousMenuState = this.state.menuActive;
		this.setState(function(state){
			state.menuActive = !previousMenuState;
			return state;
		});
	}
	
	messengerInputChangeHandler(event){
		const data = event.target.value;
		this.setState(function(state){
			state.messengerInput = data;
			return state;
		});
	}

	messengerInputFocusHandler(active){
		if (active === false && this.state.messengerInput !== null && this.state.messengerInput.length > 10){
			return;
		}
		this.setState(function(state){
			state.messageInputActive = active;
			return state;
		});
		if (active){
			setTimeout(
				this.messengerScrollCanvas,
				250
			);	
		}
	}

	messengerScrollCanvas(){
		if (this.elemContentScroll != null && this.elemContentScroll.current != null){
			this.elemContentScroll.current.scrollTo({
				top: 999999,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	getMessageSenderName(senderId){
		var senders = this.state.activeConsultData.senders;
		if (senders != null && senders.length > 0){
			for (var i=0; i<senders.length; i++){
				if (senders[i].id === senderId){
					return senders[i].name;
				}
			}
		}
		return '';
	}

	handleConsultChange(value){
		if (this.state.activeStudentData === null
				|| this.state.activeStudentData.consults.length <=0
					|| this.state.consults.length <= 0){
			return;
		}
		var consult = null;
		for (var i=0; i<this.state.consults.length; i++){
			if (this.state.consults[i].value === value){
				consult = this.state.activeStudentData.consults[i];
				break;
			}
		}
		if (consult !== null){
			var userId = this.state.activeStudentData.userId;
			var consultKey = userId+'-'+consult.journeyId+'-'+consult.trailId;
			this.setState(function(state){
				state.activeConsultKey = consultKey;
				state.activeConsultData = null;
				state.messageInputActive = false;
				state.messengerInput = '';
				return state;
			});
			this.loadMessages(userId, consult.journeyId, consult.trailId);
		}
	}

	setModalChangePasswordVisible(isVisible){
		this.setState(function(state){
			state.isChangePasswordModalOpen = isVisible;
			return state;
		});
	}

	displayDatetime(timestamp){
		return timestamp != null ? DatetimeManager.formatTimestamp( timestamp ) : '-';
	}

	renderConsultMessages(){
		if (this.state.activeConsultData === null
			|| this.state.activeConsultData.messages === null
				|| this.state.activeConsultData.messages.length <= 0)
		{
			return <></>;
		}
		var lastMessageIndex = this.state.activeConsultData.messages.length - 1;
		var showDeleteButton = (this.state.activeConsultData.messages[lastMessageIndex].type === 'A');
		return <>
			{
				this.state.activeConsultData.messages.map(function(message, messageIndex){
					return <Comment key={messageIndex}>
						<Comment.Content
							className={
								"MESSENGER_BALLOON" +
								(message.type === "Q" ? " STUDENT" : " MENTOR") +
								(message.status === 'D' ? " DELETED" : "")
							}
						>
							<Comment.Author as='span'>
								{	message.type === "A"
									? this.getMessageSenderName(message.senderId)
									: this.state.activeConsultData.userName
								}
							</Comment.Author>
							<Comment.Metadata>
								<div>{ this.displayDatetime( message.sentDate ) }</div>
								{
									showDeleteButton && messageIndex === lastMessageIndex && message.status !== 'D'
									?	<>
											<Popup
												trigger={
													<Icon
														link
														color='red'
														name='trash'
														onClick={()=>{this.showDeleteMessageConfirmation(true)}}
													/>
												}
												content='Excluir a mensagem'
												on='hover'
											/>
											<Confirm
												open={this.state.isMessageDeletePending}
												content='Deseja mesmo excluir a sua mensagem?'
												cancelButton="Cancelar"
												confirmButton="Pode Excluir"
												onCancel={()=>{this.showDeleteMessageConfirmation(false)}}
												onConfirm={this.deleteLastMessage}
											/>
										</>
									:	<></>
								}
							</Comment.Metadata>
							<Comment.Text>
								{
									message.status === 'D'
									?	<span><Icon name='ban' /> Mensagem excluída</span>
									:	<ReactMarkdown
											source={message.content}
											className='markdown-body MESSENGER_TEXT'
											renderers={
												{
													link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
													image: MDImage,
													code: CodeBlock
												}
											}
										/>
								}
							</Comment.Text>
						</Comment.Content>
					</Comment>;
				},this)
			}
		</>;
	}

	renderMessenger(){
		if (this.state.activeStudentData === null || this.state.activeStudentData.userId === null){
			return <></>;
		}
		
		return <>
			<Grid verticalAlign='middle' className="MESSENGER_HEADER">
				<Grid.Row className="MESSENGER_HEADER_ROW">
					<Grid.Column width="12" floated='left'>
						<span className="MESSENGER_HEADER_TITLE">{ this.state.activeStudentData.userName } - { this.state.activeStudentData.programTitle }
						</span>
						{
							this.state.consults.length > 0
							?	<Header.Subheader>
									<Dropdown 
										scrolling
										value={this.state.activeConsultKey}
										onChange={(event, {value})=>{this.handleConsultChange(value)}}  
										options={this.state.consults} 
									/>
								</Header.Subheader>
							:	<></>
						}
					</Grid.Column>

					<Grid.Column width="4" floated='right'>
						<Popup 
							content='Encerrar Chat'
							trigger={
								<Button 
								basic color='red'
								onClick={() => {
									this.closeChat()
									this.closeMessenger()
								}} >
									<Icon.Group size='large'>
										<Icon name='comments outline' />
										<Icon corner='top right' name='close' />
									</Icon.Group>
								</Button>
							}
						/>
						<Popup 
							content='Ver como Aluno' 
							trigger={
								<Button 
									basic color='grey'
									onClick={() => this.setCandidateToMentor(this.state.activeStudentData.userId)}
								>
									<Icon.Group size='large'>
										<Icon name='spy' />
									</Icon.Group>
								</Button>
							} 
						/>
						{' '}
						<Button icon circular onClick={this.closeMessenger} >
							<Icon name='close' />
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<div className="MESSENGER_CONTAINER">
				<div className="MESSENGER_CANVAS" ref={this.elemContentScroll}>
					<Comment.Group className="MESSENGER_LINE">
						{	this.renderConsultMessages() }
						<Comment>
							<Comment.Content className="MESSENGER_INPUT_BALLOON">
								<Comment.Author as='span'>Resposta:</Comment.Author>
								<Comment.Text>
									<TextArea
										className={'MESSENGER_INPUT' + (this.state.messageInputActive?' ACTIVE':'')}
										placeholder="escreva aqui a sua mensagem..." 
										onFocus={()=>{this.messengerInputFocusHandler(true)}}
										onBlur={()=>{this.messengerInputFocusHandler(false)}}
										value={this.state.messengerInput} 
										onChange={(event)=>{this.messengerInputChangeHandler(event)}}
										disabled={this.state.isSendingMessage}	   
									/>
									<Button
										icon="paper plane"
										size="small"
										content="Enviar"
										color="black"
										floated="right"
										disabled={this.state.messengerInput === null || this.state.messengerInput.length < 10}
										onClick={this.sendMessage}
									/>
								</Comment.Text>
							</Comment.Content>
						</Comment>
					</Comment.Group>
				</div>
			</div>
		</>;
	}

	renderStudentList(){
		return <>
			{
				this.state.students.map(function(student, studentIndex){
					var isActive = this.state.activeConsultKey === (student.id+'-'+student.journeyId+'-'+student.trailId);
					return <Menu.Item
						key={studentIndex}
						content={
							<>
								<span className="MENU_TITLE">{student.name}&nbsp;&nbsp;</span>
								<div className="MENU_SUBTITLE">{student.trailTitle}</div>
							</>
						}
						onClick={()=>{this.messengerConsultClickHandler(studentIndex)}} 
						className={
							"MENU_ITEM"+
							( student.messageType === 'Q' && (student.messageStatus !== 'D' && student.messageStatus !== 'C') ?	" QUESTION" : "")+
							(isActive ? " ACTIVE" :	"")
						}
					/>
				}, this)
			}
		</>;		
	}

	render(){
		document.title = 'Mentor :: Pragma';
		if(!this.state.isInitialized){
			return <></>;
		}
		return <div className="MAIN_FRAME">
			<Menu fixed="top" inverted borderless size="large" className="MENU_TOP">
				<Menu.Item icon="bars" onClick={this.menuMobileClickHandler} className="MENU_MOBILE_BUTTON" />
				<Menu.Item className="MENU_BRAND">
					<Link to="/" ><Image src={eIcon} /></Link>
				</Menu.Item>
				<Menu.Menu position="right" >
					<Dropdown
						item icon={null}
						trigger={
							<>
								<span className="HIDE_ON_MOBILE">
									{this.state.userData.firstName}
								</span>
								<Image avatar src={profileIcon} className="DD_AVATAR" /> 
							</>
						}
						className="DD_MENU_BUTTON"
					>
						<Dropdown.Menu className="DD_MENU">
							<Dropdown.Header icon="user" content={this.state.userData.firstName + ' '  + this.state.userData.lastName} className="DD_MENU_LABEL" />
							<Dropdown.Header icon="mail" content={this.state.userData.email} className="DD_MENU_LABEL" />
							<Dropdown.Divider />
							<Dropdown.Item icon="lock" text='Trocar Senha' className="DD_MENU_ITEM" onClick={ ()=>{this.setModalChangePasswordVisible(true)} } />
							<Dropdown.Item icon="power off" text='Sair' className="DD_MENU_ITEM" onClick={this.logout} />
						</Dropdown.Menu>
					</Dropdown>
				</Menu.Menu>
			</Menu>

			<div className="CONTENT_CONTAINER">
				<div className="MESSENGER_CONTENT">
					{ this.renderMessenger() }
				</div>
			</div>

			<Menu fixed="left" vertical size="large" className={'MENU_LEFT' + (this.state.menuActive?'':' HIDE')} >
				{ this.renderStudentList() }
			</Menu>

			<ChangePassword
				open={this.state.isChangePasswordModalOpen} 
				onDone={this.onDoneChangePasswordModal} 
				onCancelClick={()=>{ this.setModalChangePasswordVisible(false) }} 
			/>
		
		</div>;

	}

}

function MDImage(props) {
 	return <img {...props} alt="" style={{maxWidth: '100%'}} />
}

export default Messenger;
