import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import { 
	Icon, 
	Header
} from 'semantic-ui-react';
import './NotFound.css';

class NotFound extends React.Component {

	render(){
		return <>
			<div className="NOTFOUND_CONTAINER">
				<div className="NOTFOUND_CONTENT">
					<Icon name='search' size="big" />
					<Header as="h1">Ops!</Header>
					<Header as="h4">Não foi possível encontrar o endereço.</Header>
				</div>
			</div>
		</>;
	}
}

export default NotFound;