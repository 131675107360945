import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Messenger from './Messenger';
import NotFound from './NotFound';

export default function AppRouter() {
  return (
    <Switch>
        <Route exact path="/" component={Messenger} />
        <Route component={NotFound} />
    </Switch>
  )
}