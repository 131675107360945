
import moment from 'moment';
import 'moment/locale/pt-br';

class DatetimeManager{

	static formatTimestamp(timestamp){
		moment.locale('pt-BR');
		return moment(timestamp).format('lll');
	}

}

export default DatetimeManager;